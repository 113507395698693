import $ from "jquery";

export const isElementAboveTop = (el, top = 0) => {
  var rect = el.getBoundingClientRect();
  return rect.top < top;
}

export const hideMobileMenu = () => {
  const navCollapse = $("#navbarCollapse").data('bs.collapse');
  if(navCollapse) {
      navCollapse.hide();
  }
}