import $ from "jquery";
import initShowOnScroll from "./show-on-scroll";
import { isElementAboveTop, hideMobileMenu } from './utils';

const illustationAnimation = () => {
    const $body = $('body');
    const el = $(".good-design-section")[0];
    const elTopPadding = 60;
    const elGutterSpace = 10
    let top = $('.f-header').outerHeight() - elTopPadding + elGutterSpace;
    top = Math.abs(top);
    const isAbove = isElementAboveTop(el, top);
    if(isAbove) {
        $body.removeClass('has-logo');
    } else {
        $body.addClass('has-logo');
    }
};

$(document).on('turbolinks:load', function () {
  const $header = $('#f-header');
  const $logoSection = $('#logo-section');
  const $photomHeader = $('.phantom-header');

  initShowOnScroll();

  $('a[href^="#"]').on('click', function (e) {
      e.preventDefault();

      const target = this.hash,
          $target = $(target);

        hideMobileMenu();

      $('html, body').animate({
          'scrollTop': $target.offset().top - $header.outerHeight()
      }, 500);
  });

  const canShowHeader = () => {
    const { pageYOffset } = window;
    return pageYOffset > 10;
  }

  let isVisible = !canShowHeader();
  const duration = 1000;
  const animateHeader = (top, height) => {
    $header.stop().animate({
        top: `${top}px`
    }, duration);
    $photomHeader.stop().animate({
        height: `${height}px`
    }, duration)
  }

  let isAnimationStarted = false;
  const gutterSpace = 25;
  const initAnimation = () => {
    illustationAnimation();
    if(!isAnimationStarted) {
        if(canShowHeader()) {
            if(!isVisible) {
                isAnimationStarted = true;
                animateHeader(0, $header.outerHeight() + gutterSpace);
                $logoSection.stop().slideUp(duration, () => {
                    isAnimationStarted = false;
                });
                isVisible = true;
            }
        } else {
            if(isVisible) {
                isAnimationStarted = true;
                isVisible = false;
                hideMobileMenu();
                animateHeader(-$header.outerHeight(), $('#logo-section').outerHeight() + gutterSpace);
                $logoSection.stop().slideDown(duration, () => {
                    isAnimationStarted = false;
                });
            }
        }
    }
  }

  initAnimation();

  $(window).scroll(initAnimation);
});
